.contacts__container {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .contacts__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
  }
}

.contacts__link {
  transition: color 0.25s ease-out;
  color: var(--color-accent);
  line-height: 1.5;
}

.contacts__link:hover {
  color: var(--color-visited);
}

@media screen and (min-width: 600px) {
  .main__page .contacts__link {
    padding: 0 0 0 25px;
  }
}
@media screen and (min-width: 1200px) {
  .contacts__link {
    padding: 0;
  }
}

@media screen and (max-width: 600px) {
  .contacts__link {
    padding: 0;
  }
}
