.contacts__title {
  font: var(--font-h-l);
}

.contacts.wrapper {
  box-sizing: border-box;
  margin: 0px 15px 0;
}

.mainPage .contacts {
  opacity: 0;
  transform: translate3d(0px, 30px, -20px);
  will-change: opacity, transform;
}

.mainPage .contacts.animation {
  opacity: 1;
  transform: perspective(1000px) translate3d(0px, 0px, 0px);
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

/* Main Page */
.page__wrapper .contacts.wrapper {
  box-sizing: content-box;
  margin: 240px auto;
  padding: 0 46px;
}

/* Contacts Page */
.contactsPage.page__wrapper .contacts.wrapper {
  margin: 0 auto 0;
}

@media screen and (max-width: 600px) {
  .contactsPage .contacts.wrapper {
    margin: 32px 0 120px;
  }
}

@media screen and (max-width: 600px) {
  .page__wrapper .contacts.wrapper {
    margin: 120px 0;
    padding: 0 15px;
  }
}

.contacts.noTitle .contacts__title {
  display: none;
}

@media screen and (max-width: 600px) {
  .contacts__title {
    font-size: 2.06rem;
  }
}

.contacts__itemTitle {
  width: 50%;
  color: var(--color-grey);
  font: var(--font-ml);
}

@media screen and (max-width: 600px) {
  .contacts__itemTitle {
    width: 100%;
    font: var(--font-m);
  }
}

.contacts__block {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 50%;
}

@media screen and (max-width: 900px) {
  .contacts__block {
    width: 100%;
    /* margin: 0;
    gap: 0; */
  }
}

@media screen and (max-width: 600px) {
  .contacts__block {
    gap: 0;
    margin: 0;
  }
}

@media screen and (min-width: 900px) {
  .contacts__block:last-of-type .contacts__itemTitle {
    width: 20%;
  }
}

.contacts__item {
  display: flex;
  justify-content: flex-start;
}

@media screen and (max-width: 600px) {
  .contacts__item {
    flex-direction: column;
    margin: 28px 0 0;
  }
}

.contactsPage .contacts__item {
  flex-direction: column;
  margin: 0 0 13px 10px;
}

@media screen and (max-width: 600px) {
  .contactsPage .contacts__item {
    margin: 30px 0 0;
  }
}

.contactsPage .contacts__itemTitle {
  padding: 0 0 10px;
}
